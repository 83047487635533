@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

.position-marker-icon {
  font-size: 2em;
  color: indianred;
}

.water-marker-icon {
  font-size: 2em;
  color: skyblue;
}
